import {
  createActionGroup,
  createFeature,
  createFeatureSelector,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { VersionRequirements } from '../models/VersionRequirements';
import { OperatingSystem } from '@capacitor/device/dist/esm/definitions';

export interface VersionReviewState {
  requestingVersionParameters: boolean;
  versionRequirements: VersionRequirements[];
}

export const initialState: VersionReviewState = {
  requestingVersionParameters: false,
  versionRequirements: [],
};

export const VersionReviewActions = createActionGroup({
  source: 'version-review',
  events: {
    Clear: emptyProps(),
    'request version parameters': emptyProps(),
    'requesting version parameters': props<{
      requestingVersionParameters: boolean;
    }>(),
    'set version parameters': props<{
      versionRequirements: VersionRequirements[];
    }>(),
  },
});

export const versionReviewFeature = createFeature({
  name: 'versionReview',
  reducer: createReducer(
    initialState,
    on(
      VersionReviewActions.requestingVersionParameters,
      (state, { requestingVersionParameters }) => ({
        ...state,
        requestingVersionParameters,
      })
    ),
    on(
      VersionReviewActions.setVersionParameters,
      (state, { versionRequirements }) => ({
        ...state,
        versionRequirements,
        requestingVersionParameters: false,
      })
    ),
    on(VersionReviewActions.clear, () => initialState)
  ),
});

export const versionReviewSelector =
  createFeatureSelector<VersionReviewState>('versionReview');
export const selectMinVersion = (os: OperatingSystem) =>
  createSelector(versionReviewSelector, state => {
    return state.versionRequirements.find(versionRequirement =>
      versionRequirement.os?.toLowerCase()?.includes(os)
    )?.minimumallowedversion;
  });

export const requestingVersionParametersSelector = createSelector(
  versionReviewSelector,
  state =>
    state.requestingVersionParameters ? state.requestingVersionParameters : true
);
